<template>
  <div class="page">
    <Sidebar />
    <div class="main">
      <Navbar class="w-full" />
      <section class="px-4 pb-12 mt-5 md:px-12 min-h-screen">
        <div class="mt-8">
          <h1 class="page-title">Requests</h1>
          <div class="w-full relative overflow-hidden mb-8">
            <Tab :tabItems="tabSections" class="tab-mb" />
          </div>

          <template v-if="isLoadingData">
            <Loading />
          </template>
          <template v-else>
            <Table
              :header="tableHeader"
              :requests="openRequests"
              v-if="showOpenRequests"
            />
            <Table
              :header="tableHeader"
              :requests="draftRequests"
              v-if="showDraftRequests"
            />
            <Table
              :header="tableHeader"
              :requests="changeRequests"
              v-if="showChangeRequests"
            />
            <Table
              :header="tableHeader"
              :requests="pendingRequests"
              v-if="showPendingRequests"
            />
            <Table
              :header="tableHeader"
              :requests="completedRequests"
              v-if="showCompletedRequests"
            />
            <Table
              :header="tableHeader"
              :requests="cancelledRequests"
              v-if="showCancelledRequests"
            />
          </template>
        </div>
      </section>
    </div>
    <NavBottom/>
  </div>
</template>

<script>
import Navbar from "@/components/Layout/Navbar";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
import Tab from "@/components/Tabs/Tab";
import Table from "@/components/Table";
import Loading from "@/components/Preloaders/Loading";
import { mapActions, mapGetters } from "vuex";
import NavBottom from "@/components/Layout/NavBottom";

export default {
  name: "Requests",
  components: {
    NavBottom,
    Loading,
    Navbar,
    Sidebar,
    Tab,
    Table,
  },
  data() {
    return {
      tableHeader: [
        "Destination",
        "Reference Nr.",
        "Name",
        "Request value",
        "Date",
        "Status",
      ],
      tabSections: [
        { name: "Open", isActive: true, textColor: "text-black-base" },
        { name: "Drafts", isActive: false, textColor: "text-grey-dark" },
        { name: "Changes", isActive: false, textColor: "text-grey-dark" },
        { name: "Pending", isActive: false, textColor: "text-grey-dark" },
        { name: "Completed", isActive: false, textColor: "text-grey-dark" },
        { name: "Cancelled", isActive: false, textColor: "text-grey-dark" },
      ],
    };
  },

  beforeMount() {
    this.getTripRequests();
  },

  methods: {
    ...mapActions({
      getTripRequests: "requests/getTripRequests",
    }),
  },
  computed: {
    ...mapGetters({
      openRequests: "requests/openRequests",
      draftRequests: "requests/draftRequests",
      changeRequests: "requests/changeRequests",
      pendingRequests: "requests/pendingRequests",
      completedRequests: "requests/completedRequests",
      cancelledRequests: "requests/cancelledRequests",
      isLoadingData: "requests/isLoadingData",
    }),

    showOpenRequests() {
      return this.tabSections[0].isActive;
    },
    showDraftRequests() {
      return this.tabSections[1].isActive;
    },
    showChangeRequests() {
      return this.tabSections[2].isActive;
    },
    showPendingRequests() {
      return this.tabSections[3].isActive;
    },
    showCompletedRequests() {
      return this.tabSections[4].isActive;
    },
    showCancelledRequests() {
      return this.tabSections[5].isActive;
    },
  },
};
</script>
<style scoped>
.page {
  @apply flex overflow-hidden bg-grey-light h-100vh;
  @apply pb-12 md:p-0;
}
.page-title {
  @apply font-sans font-bold text-black-base text-xl sm:text-2xl mb-8;
}
.main {
  @apply relative self-start w-full h-full overflow-y-auto;
}
.tab-mb {
  @apply sm:mb-0 overflow-x-auto w-full;
}
.btn {
  @apply xs:hidden bg-gradient-to-l from-grey-light pl-3 absolute transform -translate-y-1/2 top-1/2 right-0;
}
</style>