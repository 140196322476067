<template>
  <router-link
      :to="{ name: 'Request', params: { requestId: request.id } }"
      class="t-row"
  >
    <div class="row-mb">
      <div class="w-full">
        <div class="row-mb-header">
          <timeago
              :auto-update="60"
              :datetime="request.updatedAt"
              class="row-mb-date"
          >
          </timeago>
          <div v-if="showStatus" class="status-container-mb">
            <span :class="statusStyle">
              {{ requestStatus }}
            </span>
          </div>
        </div>

        <div class="row-mb-name">{{ name }}</div>
        <div class="row-mb-reference">Reference nr. {{ request.id }}</div>
        <div class="row-mb-text">{{ request.tripDestination }}</div>
        <div class="row-mb-text">{{ request.tripCurrency }} {{ totalTripBudget }} / traveler</div>
      </div>
      <div class="row-mb-icon-container">
        <i class="bx bx-chevron-right bx-sm absolute bottom-0"></i>
      </div>
    </div>

    <div class="row-text font-semibold">
      {{ request.tripDestination }}
    </div>
    <div class="row-text">
      {{ request.id }}
    </div>
    <div class="row-text">
      {{ name }}
    </div>
    <div class="row-text">
      {{ request.tripCurrency }} {{ totalTripBudget }}
    </div>
    <div class="row-text">
      <timeago :auto-update="60" :datetime="request.updatedAt"></timeago>
    </div>
    <div v-if="showStatus" class="status-container-dt">
      <span :class="statusStyle">
        {{ requestStatus }}
      </span>
    </div>
    <div :class="[iconStyle]">
      <i class="bx bx-chevron-right bx-sm"></i>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "TableRow",
  props: {
    request: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currency: this.request.tripCurrency,
      region: "en-FR",
    };
  },
  methods: {
    formatToCurrency(number) {
      return new Intl.NumberFormat(this.region, {
        style: "currency",
        currency: this.currency,
      }).format(number);
    },
  },
  computed: {
    name() {
      return this.request.tripFirstname + " " + this.request.tripLastname;
    },
    iconStyle() {
      if (this.showStatus == false) return "icon-style";

      return "icon-style";
    },
    requestStatus() {
      if (this.request.tripRequestStatus === "review") return "pending";

      return this.request.tripRequestStatus;
    },
    statusStyle() {
      let status = this.request.tripRequestStatus;

      if (status == "pending") return "status status-pending";

      if (status == "review") return "status status-pending";

      if (status == "open") return "status status-open";

      if (status == "draft") return "status status-draft";

      if (status == "completed") return "status status-completed";

      if (status == "changes") return "status status-changes";

      return "status status-cancelled";
    },
    totalTripBudget() {
      let budget = this.request.taBudget ? this.request.taBudget : this.request.tripBudget;
      return (
          (this.request.tripAdults + this.request.tripChildren) *
          budget
      ).toLocaleString();
    },
  },
};
</script>
<style scoped>
.t-row {
  @apply grid grid-flow-col grid-cols-12 gap-4 relative;
  @apply sm:h-14 sm:px-6 w-full mb-4;
  @apply bg-white shadow-sm sm:hover:shadow-md;
  @apply transition duration-300 rounded;
}

.row-mb {
  @apply sm:hidden col-span-12 flex justify-between p-4;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-5;
}

.row-mb-reference {
  @apply text-sm text-black-lighter leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}

.icon-style {
  @apply hidden sm:flex justify-end items-center text-black-lightest absolute top-1/2 transform -translate-y-1/2 right-4;
}

.row-text {
  @apply text-sm font-sans text-black-base col-span-2 hidden sm:flex items-center;
}

.status-container-mb {
  @apply ml-auto;
}

.status-container-dt {
  @apply hidden sm:flex items-center;
}

.status-container-mb {
  @apply ml-auto;
}

.status {
  @apply text-xs font-sans inline-block px-2 py-1 rounded-full;
}

.status-pending {
  @apply text-yellow-text bg-yellow-light;
}

.status-open {
  @apply text-teal-text bg-teal-light;
}

.status-completed {
  @apply text-green-text bg-green-light;
}

.status-cancelled {
  @apply text-red-text bg-red-light;
}

.status-changes {
  @apply text-violet-text bg-violet-light;
}

.status-draft {
  @apply text-black-base bg-grey-base;
}
</style>