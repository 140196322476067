<template>
  <nav class="tab">
    <ul class="flex border-b border-grey-base">
      <li
        @click="toggleTabItem(index)"
        v-for="(tabItem, index) in tabItems"
        :key="index"
        class="flex flex-wrap"
      >
        <span :class="['tab-item', tabItem.textColor]">{{ tabItem.name }}</span>
        <div
          v-show="tabItem.isActive"
          class="selector h-px w-full bg-black-base"
        ></div>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "Tab",
  props: {
    tabItems: {
      type: Array,
      default: () => [
        { name: "Item 1", isActive: true, textColor: "text-black-base" },
        { name: "Item 1", isActive: false, textColor: "text-grey-dark" },
      ],
    },
  },
  methods: {
    toggleTabItem(itemIndex) {
      for (let [index, tabItem] of this.tabItems.entries()) {
        tabItem.isActive = false;
        tabItem.textColor = "text-grey-dark";
        if (itemIndex == index) {
          tabItem.isActive = true;
          tabItem.textColor = "text-black-base";
        }
      }
    },
  },
};
</script>
<style scoped>
.tab {
  @apply relative;
}
.tab-item {
  @apply pr-6 pb-2 cursor-pointer font-semibold text-sm sm:text-base;
}
</style>